import Head from 'next/head';

import { Footer } from 'modules/common/Footer';
import { FooterCollection } from 'modules/common/Footer/Footer.types';
import { Header } from 'modules/common/Header';
import { HeaderCollection } from 'modules/common/Header/Header.types';
import { Cases } from 'modules/home/Cases';
import { CasesCollection } from 'modules/home/Cases/Cases.types';
import { Contact } from 'modules/home/Contact';
import { ContactCollection } from 'modules/home/Contact/Contact.types';
import { Features } from 'modules/home/Features';
import { FeaturesCollection } from 'modules/home/Features/Features.types';
import { Hero } from 'modules/home/Hero';
import { HeroCollection } from 'modules/home/Hero/Hero.types';
import { Integrations } from 'modules/home/Integrations';
import { IntegrationsCollection } from 'modules/home/Integrations/Integrations.types';
import { Plans } from 'modules/home/Plans';
import { PlansCollection } from 'modules/home/Plans/Plans.types';
import { Solutions } from 'modules/home/Solutions';
import { SolutionsCollection } from 'modules/home/Solutions/Solutions.types';
import { Testimonials } from 'modules/home/Testimonials';
import { TestimonialsCollection } from 'modules/home/Testimonials/Testimonials.types';

import contentfulData from '../services/contentful';
import { queryHome } from '../services/contentful/querys';

type HomeProps = {
  hero: HeroCollection;
  cases: CasesCollection;
  testimonials: TestimonialsCollection;
  integrations: IntegrationsCollection;
  features: FeaturesCollection;
  solutions: SolutionsCollection;
  contact: ContactCollection;
  plans: PlansCollection;
  menuPrincipal: HeaderCollection;
  menuRodape: FooterCollection;
};

const Home = ({
  cases,
  contact,
  features,
  hero,
  integrations,
  menuPrincipal,
  menuRodape,
  plans,
  solutions,
  testimonials,
}: HomeProps) => {
  return (
    <>
      <Head>
        <title>Olist Vnda: Sua loja virtual com a melhor plataforma de e-commerce</title>
        <link rel="icon" href="/favicon.ico" />
        <meta charSet="UTF-8" />
        <meta
          name="description"
          content="A Olist Vnda é a melhor tecnologia de ecommerce para o crescimento da sua loja virtual. Confira os recursos exclusivos para impulsionar o seu negócio!"
        />
        <meta name="keywords" content="E-commerce, plataforma, omnichannel, direct, pdv, varejo" />
        <meta name="author" content="Olist Vnda - Tecnologia em E-commerce" />
        <link rel="canonical" href="https://www.vnda.com.br/" />
        <meta name="robots" content="index, follow" />
      </Head>
      <Header menus={menuPrincipal.menus} />
      <main>
        <Hero title={hero.title} subtitle={hero.subtitle} image={hero.image} />
        <Solutions items={solutions.items} />
        <Cases items={cases.items} />
        <Plans items={plans.items} />
        <Features items={features.items} />
        <Integrations title={integrations.title} image={integrations.image} />
        <Testimonials items={testimonials.items} />
        <Contact title={contact.title} subtitle={contact.subtitle} formTitle={contact.formTitle} />
      </main>
      <Footer menus={menuRodape.menus} />
    </>
  );
};

export default Home;

export async function getServerSideProps() {
  const data = await contentfulData(queryHome());

  return {
    props: data,
  };
}

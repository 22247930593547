import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Cases = styled.section`
  margin-top: 80px;

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin-top: 48px;
        }
      `
    );
  }}
`;

export const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 16px;
          flex-direction: column;
          text-align: center;
        }
      `
    );
  }}
`;

export const Content = styled(Flex)`
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  max-width: 486px;
  width: 50%;

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          width: 100%;
          margin-top: 32px;
        }
      `
    );
  }}

  > a {
    width: 25%;
    img {
      cursor: pointer;
      height: 40px !important;
      margin: 0 !important;
      max-height: 40px !important;
      max-width: 100% !important;
      min-height: 40px !important;
      min-width: 100% !important;
      object-fit: contain !important;
      padding: 0 !important;
      width: 100% !important;
    }
  }
`;

export const Title = styled.div`
  margin-right: 80px;
  max-width: 343px;
  &:hover {
    a {
      opacity: 1;
    }
  }

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin-right: 0;
        }
      `
    );
  }}

  a {
    align-items: center;
    color: #0c29d0;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 150%;
    margin-top: 6px;
    opacity: 0;
    text-decoration: none;
    transition: 0.3s;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            justify-content: center;
          }
        `
      );
    }}

    svg {
      margin-left: 13px;
    }
  }
`;

import styled, { css } from 'styled-components';

export const Integrations = styled.section`
  background: #f5f5f5;
  overflow: hidden;
  padding-bottom: 80px;
  padding-top: 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          padding-bottom: 48px;
          padding-top: 48px;
        }
      `
    );
  }}
`;

export const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Content = styled.div`
  display flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          flex-direction: column;
        }
      `
    );
  }}
`;

export const Tabs = styled.ul`
  width: 50%;
  display flex;
  justify-content: space-between;
  align-items; center;
  flex-wrap: wrap;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 60%;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}

  h2 {
    margin-bottom: 48px;
    max-width: 500px;
  }
`;

export const Tab = styled.li`
  margin-bottom: 40px;
  width: calc(50% - 25px);
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          width: 100%;
          margin-bottom: 24px;
        }
      `
    );
  }}
`;

export const Icon = styled.div`
  height: 26px;
  margin-bottom: 16px;
  width: 26px;
  > svg {
    height: 26px;
    width: 26px;
  }
`;

export const Right = styled.div`
  width: 50%;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 35%;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}
  > span {
    overflow: inherit !important;
  }
  img {
    height: 40vw !important;
    max-height: 40vw !important;
    max-width: 50vw !important;
    min-height: 40vw !important;
    min-width: 50vw !important;
    object-fit: contain;
    width: 50vw !important;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            height: 90vw !important;
            max-height: 90vw !important;
            max-width: 100vw !important;
            min-height: 90vw !important;
            min-width: 100vw !important;
            width: 100vw !important;
          }
        `
      );
    }}
  }
`;

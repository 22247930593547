import { memo } from 'react';

const Circle = () => (
  <svg
    width="610"
    height="734"
    viewBox="0 0 610 734"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="366.5" cy="366.75" r="366.5" fill="url(#paint0_linear_1705_24859)" />
    <defs>
      <linearGradient
        id="paint0_linear_1705_24859"
        x1="366.5"
        y1="0.25"
        x2="366.5"
        y2="733.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e6eeff" />
        <stop offset="1" stopColor="#e6eeff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const CircleIcon = memo(Circle);

const Circle2 = () => (
  <svg
    width="594"
    height="597"
    viewBox="0 0 594 597"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="298.5" cy="298.5" r="298.5" fill="url(#paint0_linear_1705_25777)" />
    <defs>
      <linearGradient
        id="paint0_linear_1705_25777"
        x1="298.5"
        y1="0"
        x2="298.5"
        y2="597"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e6eeff" />
        <stop offset="1" stopColor="#e6eeff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const Circle2Icon = memo(Circle2);

const Circle3 = () => (
  <svg
    width="550"
    height="550"
    viewBox="0 0 550 550"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="275" cy="275" r="275" fill="url(#paint0_linear_2009_27312)" />
    <defs>
      <linearGradient
        id="paint0_linear_2009_27312"
        x1="275"
        y1="0"
        x2="275"
        y2="550"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e6eeff" />
        <stop offset="1" stopColor="#e6eeff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const Circle3Icon = memo(Circle3);

import * as S from './Integrations.style';
import * as T from './Integrations.types';

const integrations = [
  {
    title: 'Centenas de integrações',
    description:
      'Centenas de integrações com mais de 50 ERPs, gateways de pagamento, adquirentes, soluções logísticas, CRMs e centenas de apps líderes de segmento.',
    icon: {
      url: '/images/icon_integracoes.svg',
    },
  },
  {
    title: 'API aberta',
    description: 'API aberta que garante a visibilidade e facilidade para qualquer integração.',
    icon: {
      url: '/images/icon_api.svg',
    },
  },
  {
    title: 'Developer first',
    description: 'Developer first para que o seu negócio seja livre de bugs e erros.',
    icon: {
      url: '/images/icon_developers_first.svg',
    },
  },
];

export const Integrations: React.FC<T.IntegrationsCollection> = ({ image, title }) => {
  return (
    <S.Integrations>
      <S.Container>
        <S.Content>
          <S.Tabs>
            <h2>
              {title}
              <span>.</span>
            </h2>
            {integrations.map((plan) => {
              return (
                <S.Tab key={plan.title}>
                  <S.Icon>
                    <img src={plan.icon.url} alt={plan.title} />
                  </S.Icon>
                  <p>{plan.description}</p>
                </S.Tab>
              );
            })}
            {/* <a href="#">veja todas as integrações</a> */}
          </S.Tabs>

          <S.Right>
            <img src={image.url} alt={title} />
          </S.Right>
        </S.Content>
      </S.Container>
    </S.Integrations>
  );
};

import styled, { css } from 'styled-components';

export const Features = styled.section`
  padding-bottom: 80px;
  padding-top: 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          padding-bottom: 48px;
          padding-top: 48px;
        }
      `
    );
  }}
`;

export const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px 0 0;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Content = styled.div`
  align-items: flex-start;
  display flex;
  justify-content: space-between;
  position: relative;
`;

export const Tabs = styled.ul`
  width: 60%;
  display flex;
  justify-content: space-between;
  align-items; center;
  flex-wrap: wrap;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}
  h2 {
    margin-bottom: 64px;
    max-width: 500px;
  }
`;

export const Tab = styled.li`
  margin-bottom: 40px;
  width: calc(50% - 25px);

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          width: 100%;
        }
      `
    );
  }}
  h6 {
    align-items: center;
    color: #0c29d0;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    > .text {
      width: calc(100% - 48px);
    }
  }
`;

export const Icon = styled.div`
  align-items: center;
  background: #0c29d0;
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
`;

export const Bottom = styled.div`
  border-top: 1px solid #f5f5f5;
  padding-top: 32px;
  width: 100%;
  p {
    max-width: 402px;
  }
  a {
    color: #0c29d0;
    cursor: pointer;
    margin-left: 5px;
    svg {
      margin-left: 5px;
    }
  }
`;

export const Image = styled.div`
  position: relative;
  width: 100%;
  > img {
    width: 85%;
  }
  > svg {
    left: 0;
    position: absolute;
    top: 36px;
    width: 100%;
    z-index: -1;
  }
`;

export const Carrossel = styled.div`
  position: sticky;
  top: 100px;
  width: calc(40% - 27px);

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `
    );
  }}
`;

import { EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowRightIcon } from 'modules/icons/ArrowRight';
import { EllipseIcon } from 'modules/icons/Ellipse';

import * as S from './Features.style';
import * as T from './Features.types';

export const Features: React.FC<T.FeaturesCollection> = ({ items }) => {
  return (
    <S.Features>
      <S.Container>
        <S.Content>
          <S.Carrossel>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              modules={[Pagination, EffectFade]}
              effect="fade"
            >
              <SwiperSlide>
                <S.Image>
                  <img src="/images/Vnda_4dobra_cadastrodeprodutos@2x.png" alt="Integracoes" />
                  <EllipseIcon />
                </S.Image>
              </SwiperSlide>
              <SwiperSlide>
                <S.Image>
                  <img src="/images/Vnda_4dobra_checkout@2x.png" alt="Integracoes" />
                  <EllipseIcon />
                </S.Image>
              </SwiperSlide>
              <SwiperSlide>
                <S.Image>
                  <img src="/images/Vnda_4dobra_OMS@2x.png" alt="Integracoes" />
                  <EllipseIcon />
                </S.Image>
              </SwiperSlide>
              <SwiperSlide>
                <S.Image>
                  <img src="/images/Vnda_4dobra_responsivo@2x.png" alt="Integracoes" />
                  <EllipseIcon />
                </S.Image>
              </SwiperSlide>
            </Swiper>
          </S.Carrossel>
          <S.Tabs>
            <h2>
              Funcionalidades que vão otimizar sua operação<span>.</span>
            </h2>
            {items.map((feature) => {
              return (
                <S.Tab key={feature.title}>
                  <h6>
                    <S.Icon>
                      <img src={feature.image.url} alt={feature.title} width={23} height={23} />
                    </S.Icon>
                    <div className="text">{feature.title}</div>
                  </h6>
                  <p>{feature.text}</p>
                </S.Tab>
              );
            })}
            <S.Bottom>
              <h6>
                E muito mais<span>.</span>
              </h6>
              <p>
                Fale com um de nossos especialistas e descubra todas as funcionalidades
                <a href="#contato">
                  quero saber mais
                  <ArrowRightIcon />
                </a>
              </p>
            </S.Bottom>
          </S.Tabs>
        </S.Content>
      </S.Container>
    </S.Features>
  );
};

import React, { useEffect, useState } from 'react';

import { EffectFade, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './Solutions.style';
import * as T from './Solutions.types';

export const Solutions: React.FC<T.SolutionsCollection> = ({ items }) => {
  const [selectedSolution, setSelectedSolution] = useState(items[0]);
  const [topBackground, setTopBackground] = useState(0);
  const [heightBackground, setHeightBackground] = useState(0);
  const [swiper, setSwiper] = useState<any>({});

  const handleClick = (index: number, solution: T.Solution) => {
    swiper.slideTo(index);
    setSelectedSolution(solution);

    const element = document?.getElementById(`solution-${index}`);
    if (element) setTopBackground(element?.offsetTop ? element?.offsetTop : 0);

    const height = document?.getElementById(`solution-${index}`)?.clientHeight;
    if (height) setHeightBackground(height);
  };

  useEffect(() => {
    if (selectedSolution === items[0]) {
      const element = document?.getElementById(`solution-0`);
      if (element) setTopBackground(element?.offsetTop ? element?.offsetTop : 0);

      const height = document?.getElementById(`solution-0`)?.clientHeight;
      if (height) setHeightBackground(height);
    }
  });

  return (
    <S.Solutions>
      <S.Container>
        <h2>
          Soluções exclusivas para que o seu negócio esteja sempre um passo à frente<span>.</span>
        </h2>
        <p className="subtitle">
          Omnicanalidade, logística própria e recursos promocionais nativos.
        </p>
        <S.Content>
          <S.Image>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Mousewheel, EffectFade]}
              mousewheel
              effect="fade"
              onSwiper={(s) => {
                setSwiper(s);
              }}
              onSlideChange={() => handleClick(swiper.activeIndex, items[swiper.activeIndex])}
            >
              {items.map((solution) => {
                return (
                  <SwiperSlide key={solution.image.url}>
                    <img src={solution.image.url} alt={solution.title} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </S.Image>
          <S.Tabs>
            {items.map((solution, index) => {
              return (
                <>
                  <S.Tab
                    onClick={() => handleClick(index, solution)}
                    className={solution.title === selectedSolution.title ? 'active' : ''}
                    key={solution.image.url}
                    id={`solution-${index}`}
                  >
                    <S.Icon>
                      <img src="/images/growth.svg" alt="Icon Check" />
                    </S.Icon>
                    <h4>{solution.title}</h4>
                    <small>{solution.text}</small>
                  </S.Tab>
                  {selectedSolution.image.url === solution.image.url && (
                    <img
                      key={`img_${solution.image.url}`}
                      src={solution.image.url}
                      alt={solution.title}
                    />
                  )}
                </>
              );
            })}
            <S.Background style={{ top: `${topBackground}px`, height: `${heightBackground}px` }} />
          </S.Tabs>
        </S.Content>
      </S.Container>
    </S.Solutions>
  );
};

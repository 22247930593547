import themeGet from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Contact = styled.section`
  background: linear-gradient(0deg, #e6eeff -0.75%, rgba(254, 237, 225, 0) 129.99%);
  padding: 80px 0;
`;

export const Container = styled(Flex)`
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  box-sizing; border-box;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
          flex-direction: column;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Left = styled.div`
  background: #0c29d0;
  border-radius: 16px 0 0 16px;
  color: white;
  padding: 65px;
  width: 40%;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 32px;
          width: 45%;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
          padding 24px;
          box-sizing: border-box;
          border-radius: 16px 16px 0 0;
        }
      `
    );
  }}
  h2 {
    color: white;
    margin-bottom: 16px;
    margin-top: 64px;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            font-size: 32px;
          }
          @media (max-width: ${theme.breakpoints.small}) {
            max-width: 490px;
          }
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-size: 20px;
          }
        `
      );
    }}
    span {
      background: #ffffff;
    }
  }
`;

export const Form = styled.div`
  width: 60%;
  padding 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 16px 16px 0;
  background: #ffffff;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
          padding 24px;
          box-sizing: border-box;
          border-radius: 0 0 16px 16px;
        }
      `
    );
  }}
  h3 {
    margin-bottom: 32px;
    max-width: 436px;
    width: 100%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-size: 20px;
          }
        `
      );
    }}
  }
  ._form_13 {
    width: 100%;
    max-width: 436px;

    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            max-width: 90%;
          }
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            max-width: 100%;
          }
        `
      );
    }}
  }
  form {
    background-color: transparent !important;
    margin: 0 !important;
    margin-top: 8px !important;
    margin-top: 0 !important;
    max-width: 436px;
    padding: 0 !important;
    width: 100%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            max-width: 700px !important;
          }
        `
      );
    }}
    ._form-content {
      ._form_element {
        width: 100% !important;
        ._form-label {
          display: none !important;
        }
        input,
        select {
          border: 1px solid #cfcfcf !important;
          border-radius: 8px !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          letter-spacing: 0.02em !important;
          padding: 11px 20px !important;
          width: 100% !important;
          margin-bottom: 20px;
          background: white !important;
          line-height: 24px;
          font-family: ${themeGet('font')} !important;
          &:focus {
            border: 1px solid #0c29d0 !important;
          }
        }

        ._row._checkbox-radio {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          input {
            width: auto !important;
            margin: 0;
            margin-right: 10px;
            font-weight: 400;
            padding: 0;
          }
          span {
            width: calc(100% - 30px);
            font-family: ${themeGet('font')} !important;
          }
        }

        select {
          appearance: none;
          position: relative;
          background-image: url("/images/Vector(5).svg") !important;
          background-repeat: no-repeat !important;
          background-position: 95% 20px !important;
        }
      }
      ._button-wrapper {
        width: 124px !important;
        button {
          font-size: 16px !important;
          width: 124px !important;
        }
      }
    }
    ._form-branding {
      display: none;
    }
  }
`;

import { memo } from 'react';

const ArrowRight = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-3.93484e-07 6.06156L9.12281 6.0625L5.66813 9.49938L6.72563 10.5625L12 5.3125L6.75 0.0625005L5.68969 1.12375L9.12844 4.5625L-5.24619e-07 4.56156L-3.93484e-07 6.06156Z"
      fill="#0c29d0"
    />
  </svg>
);

export const ArrowRightIcon = memo(ArrowRight);

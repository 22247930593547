/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowRightIcon } from 'modules/icons/ArrowRight';
import { useParams } from 'services/params';

import * as S from './Testimonials.style';
import * as T from './Testimonials.types';

function LinkRenderer(props: any) {
  const params = useParams();
  return (
    <a href={props.href + params} target="_blank" rel="noreferrer">
      {props.children}
      <ArrowRightIcon />
    </a>
  );
}

export const Testimonials: React.FC<T.TestimonialsCollection> = ({ items }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>({});
  const [activeTab, setActiveTab] = useState(items[0]);

  return (
    <S.Testimonials>
      <S.Container>
        <h2>
          Saiba mais sobre quem cresce com Olist Vnda<span>.</span>
        </h2>
        <S.Content>
          <S.Carrossel>
            <Swiper
              spaceBetween={0}
              slidesPerView={3}
              freeMode
              watchSlidesProgress
              navigation
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {items.map((item, index) => {
                return (
                  <SwiperSlide
                    key={item.logo.url}
                    className={activeTab.logo.url === item.logo.url ? 'active' : ''}
                    onClick={() => {
                      thumbsSwiper.slideTo(index);
                      setActiveTab(item);
                    }}
                  >
                    <img src={item.logo.url} alt={item.text} height="40px" width="100px" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </S.Carrossel>

          <S.CarrosselText>
            <Swiper
              onSwiper={(swiper) => {
                setThumbsSwiper(swiper);
              }}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {items.map((item) => {
                return (
                  <SwiperSlide key={item.image.url}>
                    <img src={item.image.url} alt={item.text} height="500px" width="500px" />
                    <S.Text>
                      <ReactMarkdown components={{ a: LinkRenderer }}>{item.text}</ReactMarkdown>
                    </S.Text>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </S.CarrosselText>
        </S.Content>
      </S.Container>
    </S.Testimonials>
  );
};

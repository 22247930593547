import styled, { css } from 'styled-components';

export const Solutions = styled.section`
  background: linear-gradient(0deg, #e6eeff -0.75%, rgba(254, 237, 225, 0) 129.99%);
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: center;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          padding-bottom: 48px;
          padding-top: 48px;
        }
      `
    );
  }}

  h2 {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 673px;
  }

  .subtitle {
    margin-bottom: 64px;
  }
`;

export const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
          flex-direction: column;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Content = styled.div`
  display flex;
  justify-content: space-between;
  align-items: center;
`;

export const Image = styled.div`
  width: calc(50% - 27px);
  img {
    width: 100%;
  }
  .swiper-slide {
    img {
      opacity: 0;
    }
    &.swiper-slide-active {
      img {
        opacity: 1;
      }
    }
  }
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: calc(40% - 27px);
        }
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `
    );
  }}
`;

export const Tabs = styled.ul`
  width: 50%;
  display flex;
  justify-content: center;
  align-items; center;
  flex-direction: column;
  position: relative;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 60%;
        }

        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}
  > img {
    display: none;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            display: block;
            margin-top: 16px;
            margin-bottom: 16px;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
          }
        `
      );
    }}
  }
`;

export const Tab = styled.li`
  border-radius: 8px;
  box-sizing: border-box;
  color: #363232;
  line-height: 150%;
  overflow: hidden;
  padding: 32px 24px;
  padding-left: 97px;
  position: relative;
  text-align: left;
  transition: 0.3s;
  white-space: initial;
  width: 100%;
  z-index: 2;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 24px 12px;
          padding-left: 70px;
        }
      `
    );
  }}
  &:hover {
    cursor: pointer;
    h4 {
      color: #0c29d0;
    }
  }
  &.active {
    box-shadow: 2px 16px 24px rgba(71, 66, 66, 0.06);
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            background: white;
          }
        `
      );
    }}
  }
  h4 {
    color: #363232;
    margin-bottom: 8px;
  }
  small {
    color: #363232;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const Icon = styled.div`
  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 48px;
  justify-content: center;
  left: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  width: 48px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          left: 12px;
        }
      `
    );
  }}
  img {
    height: 26px;
    transition: 0.3s;
    width: 26px;
  }
`;

export const Background = styled.div`
  background: white;
  border-radius: 8px;
  left: 0;
  position: absolute;
  transition: 0.3s;
  width: 100%;
  z-index: 1;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `
    );
  }}
`;

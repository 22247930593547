import themeGet from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex, Link as LinkUnited } from '@olist/united';

export const Hero = styled.section`
  margin-top: 20px;
  overflow: hidden;
`;

export const Container = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          flex-direction: column;
        }
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
  > div {
    justify-content: space-between;
    padding: 0;
    width: 50%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            width: 100%;
          }
        `
      );
    }}
    > div {
      flex: 1;
      &:nth-child(2) {
        flex: 10;
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.large}) {
                display: none;
              }
            `
          );
        }}
        > a {
          padding: 22px 10px;
        }
      }
    }
  }
`;

export const Left = styled.div`
  margin-right: 32px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 60% !important;
        }

        @media (max-width: ${theme.breakpoints.extraSmall}) {
          width: 100% !important;
          margin-right: 0px;
        }
      `
    );
  }}
  h1 {
    margin-bottom: 16px;
    max-width: 574px;
  }
  p {
    max-width: 500px;
  }
`;

export const Right = styled.div`
  position: relative;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 40% !important;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          width: 100% !important;
        }
      `
    );
  }}
  > span {
    overflow: inherit !important;
  }
  svg {
    left: 0;
    position: absolute;
    top: 0;
    z-index: -9;
  }
  img {
    height: 40vw !important;
    max-height: 40vw !important;
    max-width: 50vw !important;
    min-height: 40vw !important;
    min-width: 50vw !important;
    object-fit: cover;
    object-position: left;
    padding: 50px 0;
    width: 50vw !important;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            height: 597px !important;
            max-height: 597px !important;
            max-width: 597px !important;
            min-height: 597px !important;
            min-width: 597px !important;
            width: 597px !important;
          }
          @media (max-width: ${theme.breakpoints.small}) {
            height: 450px !important;
            max-height: 450px !important;
            max-width: 450px !important;
            min-height: 450px !important;
            min-width: 450px !important;
            width: 450px !important;
          }
        `
      );
    }};
  }
`;

export const Contact = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          flex-wrap: wrap;
        }
      `
    );
  }}
`;

export const Link = styled(LinkUnited)`
  background-color: #0c29d0;
  border: 1px solid #0c29d0;
  border-radius: ${themeGet('olist.borderRadius.pill')};
  color: #ffffff;
  display: flex;
  height: 48px;
  padding: 0px 24px;
  &:hover {
    background-color: #0c29d0 !important;
    border: 1px solid #0c29d0 !important;
    box-shadow: none !important;
    color: #ffffff !important;
    svg {
      margin-left: 10px;
      width: 12px;
    }
  }
  svg {
    display: block;
    margin-left: 0px;
    overflow: hidden;
    transition: 0.3s;
    width: 0;
    path {
      fill: #ffffff !important;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Link as LinkUnited } from '@olist/united';

export const Plans = styled.section`
  overflow: hidden;
  padding-bottom: 80px;
  padding-top: 80px;
  position: relative;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          padding-bottom: 48px;
          padding-top: 48px;
        }
      `
    );
  }}
  > svg {
    height: 734px;
    position: absolute;
    right: 0;
    top: 0;
    width: 610px;
    z-index: -1;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.medium}) {
            right: auto;
            left: 60%;
          }
          @media (max-width: ${theme.breakpoints.small}) {
            display: none;
          }
        `
      );
    }}
  }
`;

export const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Content = styled.div`
  display flex;
  justify-content: space-between;
  align-items: center;
`;

export const Image = styled.div`
  width: calc(50% - 27px);
  img {
    width: 100%;
  }
  .swiper-slide {
    img {
      opacity: 0;
    }
    &.swiper-slide-active {
      img {
        opacity: 1;
      }
    }
  }
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: calc(40% - 27px);
        }
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `
    );
  }}
`;

export const Tabs = styled.ul`
  width: 50%;
  display flex;
  justify-content: center;
  align-items; center;
  flex-direction: column;
  position: relative;

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          width: 60%;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}

  h2 {
    margin-bottom: 16px;
    max-width: 500px;
  }

  .subtitle {
    margin-bottom: 32px;
  }

  > img{
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            display: block;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
          }
        `
      );
    }}
  }
`;

export const Tab = styled.li`
  box-sizing: border-box;
  color: #363232;
  line-height: 150%;
  padding: 24px;
  padding-left: 44px;
  position: relative;
  text-align: left;
  transition: 0.3s;
  white-space: initial;
  width: 100%;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
  &.active {
    border-radius: 8px;
    box-shadow: 2px 16px 24px rgba(71, 66, 66, 0.06);
    cursor: pointer;
    > div {
      svg {
        color: #0c29d0;
      }
    }
    h6 {
      color: #0c29d0;
    }
    small {
      height: auto;
      margin-top: 8px;
    }
  }
  small {
    color: #363232;
    display: block;
    font-size: 16px;
    font-weight: 400;
    height: 0;
    line-height: 150%;
    margin-top: 0px;
    overflow: hidden;
  }
`;

export const Icon = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  left: 16px;
  position: absolute;
  top: 28px;
  transition: 0.3s;
  img {
    height: 26px;
    transition: 0.3s;
    width: 26px;
  }
`;

export const Link = styled(LinkUnited)`
  background-color: #0c29d0;
  border: 1px solid #0c29d0;
  border-radius: ${themeGet('olist.borderRadius.pill')};
  color: #ffffff;
  height: 48px;
  margin-top: 32px;
  padding: 0px 24px;
  width: fit-content;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          margin: 0 auto;
        }
      `
    );
  }}
  &:hover {
    background-color: #0c29d0 !important;
    border: 1px solid #0c29d0 !important;
    box-shadow: none !important;
    color: #ffffff !important;
    svg {
      width: 12px;
      margin-left: 10px;
    }
  }
  svg {
    display: block;
    margin-left: 0px;
    width: 0;
    overflow: hidden;
    transition: 0.3s;
    path {
      fill: #ffffff !important;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const Background = styled.div`
  background: white;
  border-radius: 8px;
  left: 0;
  position: absolute;
  transition: 0.3s;
  width: 100%;
  z-index: 1;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `
    );
  }}
`;

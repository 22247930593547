import React, { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { EffectFade, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowRightIcon } from 'modules/icons/ArrowRight';
import { CircleIcon } from 'modules/icons/Circle';

import * as S from './Plans.style';
import * as T from './Plans.types';

export const Plans: React.FC<T.PlansCollection> = ({ items }) => {
  const [selectedPlan, setSelectedPlan] = useState(items[1]);
  const [topBackground, setTopBackground] = useState(0);
  const [heightBackground, setHeightBackground] = useState(0);
  const [swiper, setSwiper] = useState<any>({});

  const handleClick = (index: number, solution: T.Plan) => {
    swiper.slideTo(index);
    setSelectedPlan(solution);

    const element = document?.getElementById(`plan-${index}`);
    if (element) setTopBackground(element?.offsetTop ? element?.offsetTop : 0);

    const height = document?.getElementById(`plan-${index}`)?.clientHeight;
    if (height) setHeightBackground(height);
  };

  useEffect(() => {
    if (selectedPlan === items[0]) {
      const element = document?.getElementById(`plan-0`);
      if (element) setTopBackground(element?.offsetTop ? element?.offsetTop : 0);

      const height = document?.getElementById(`plan-0`)?.clientHeight;
      if (height) setHeightBackground(height);
    }
  });

  return (
    <S.Plans>
      <S.Container>
        <S.Content>
          <S.Tabs>
            <h2>
              Flexível para impulsionar todas as fases do seu negócio<span>.</span>
            </h2>
            <p className="subtitle">
              Temos o plano ideal para cada etapa do seu crescimento. Assim, você pode focar no seu
              negócio e não precisa se preocupar com tecnologia.
            </p>
            {items.map((item, index) => {
              return (
                <>
                  <S.Tab
                    onClick={() => handleClick(index, item)}
                    className={item.title === selectedPlan?.title ? 'active' : ''}
                    key={item.title}
                    id={`plan-${index}`}
                  >
                    <S.Icon>
                      <ArrowRightIcon />
                    </S.Icon>
                    <h6>{item.title}</h6>
                    <ReactMarkdown>{item.subtitle}</ReactMarkdown>
                  </S.Tab>
                  {selectedPlan?.image.url === item.image.url && (
                    <img key={`img_${item.image.url}`} src={item.image.url} alt={item.title} />
                  )}
                </>
              );
            })}
            <S.Link
              href={selectedPlan?.btnLink}
              target={selectedPlan?.btnLink.includes('starter') ? '_blank' : ''}
            >
              {selectedPlan?.btnText}
              <ArrowRightIcon />
            </S.Link>
            <S.Background style={{ top: `${topBackground}px`, height: `${heightBackground}px` }} />
          </S.Tabs>
          <S.Image>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Mousewheel, EffectFade]}
              mousewheel
              effect="fade"
              onSwiper={(s) => {
                setSwiper(s);
              }}
              onSlideChange={() => handleClick(swiper.activeIndex, items[swiper.activeIndex])}
            >
              {items.map((plan) => {
                return (
                  <SwiperSlide key={plan.image.url}>
                    <img src={plan.image.url} alt={plan.title} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </S.Image>
        </S.Content>
      </S.Container>
      <CircleIcon />
    </S.Plans>
  );
};

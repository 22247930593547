/* eslint-disable @next/next/no-html-link-for-pages */
import NextLink from 'next/link';

import { ArrowRightIcon } from 'modules/icons/ArrowRight';
import { useParams } from 'services/params';

import * as S from './Cases.style';
import * as T from './Cases.types';

export const Cases: React.FC<T.CasesCollection> = ({ items }) => {
  const params = useParams();

  return (
    <S.Cases id="cases">
      <S.Container>
        <S.Title>
          <h5>
            O ecossistema de marcas que mais crescem no Brasil<span>.</span>
          </h5>
          <NextLink href={`/casesdesucesso${params}`}>
            <a href={`/casesdesucesso${params}`}>
              o que dizem nossos clientes
              <ArrowRightIcon />
            </a>
          </NextLink>
        </S.Title>
        <S.Content>
          {items.map((item) => (
            <NextLink key={item.title} href={item.link}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img src={item.image.url} alt={item.title} height={40} width={100} />
              </a>
            </NextLink>
          ))}
        </S.Content>
      </S.Container>
    </S.Cases>
  );
};

import styled, { css } from 'styled-components';

export const Testimonials = styled.section`
  margin-top: 80px;
  h2 {
    margin-bottom: 50px;
  }
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin-top: 48px;
        }
      `
    );
  }}
`;

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 80px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.medium}) {
          padding: 0 48px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 0 24px;
        }
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 0 16px;
        }
      `
    );
  }}
`;

export const Content = styled.div``;

export const Carrossel = styled.div`
  margin-bottom: 14px;
  width: 100%;
  .swiper-slide {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0.4;

    &.active {
      opacity: 1;
    }

    img {
      cursor: pointer;
      height: 95px !important;
      margin: 0 !important;
      max-height: 95px !important;
      max-width: 95px !important;
      min-height: 95px !important;
      min-width: 95px !important;
      object-fit: contain !important;
      padding: 0 !important;
      width: 95px !important;
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.extraSmall}) {
              max-height: 50px !important;
              max-width: 50px !important;
              min-height: 50px !important;
              min-width: 50px !important;
              height: 50px !important;
              width: 50px !important;
            }
          `
        );
      }}
    }
  }
`;

export const CarrosselText = styled.div`
  width: 100%;
  .swiper-slide {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 50px;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            padding: 24px;
          }
        `
      );
    }}
    img {
      border-radius: 8px;
      height: 500px;
      object-fit: cover;
      overflow: hidden;
      width: 35%;
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.medium}) {
              height: 440px;
            }
            @media (max-width: ${theme.breakpoints.extraSmall}) {
              display: none;
            }
          `
        );
      }}
    }
  }
`;

export const Text = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(71, 66, 66, 0.06);
  margin-left: -10%;
  padding: 48px;
  width: 65%;
  z-index: 9;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.extraSmall}) {
          padding: 24px;
          width: 100%;
          margin-left: 0%;
        }
      `
    );
  }}
  a {
    color: #807e7e;
    display: block;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 150%;
    margin-top: 20px;
    text-align: right;
    text-decoration: none;
    transition: 0.3s;
    width: 100%;
    &:hover {
      color: #0c29d0;
      svg path {
        fill: #0c29d0;
        transition: 0.3s;
      }
    }
    svg {
      margin-left: 10px;
      path {
        fill: #807e7e;
      }
    }
  }
`;

import { memo } from 'react';

const Ellipse = () => (
  <svg
    width="500"
    height="501"
    viewBox="0 0 500 501"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="250" cy="250.5" r="250" fill="url(#paint0_linear_1470_28300)" />
    <defs>
      <linearGradient
        id="paint0_linear_1470_28300"
        x1="250"
        y1="0.5"
        x2="250"
        y2="500.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e6eeff" />
        <stop offset="1" stopColor="#e6eeff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const EllipseIcon = memo(Ellipse);

import * as S from './Contact.style';
import * as T from './Contact.types';
import { FormActiveCampaign } from './Form';

export const Contact: React.FC<T.ContactCollection> = ({ formTitle, subtitle, title }) => {
  return (
    <S.Contact id="contato">
      <S.Container>
        <S.Left>
          <img src="/images/vnda-white.svg" alt="Olist Vnda" height="44px" width="109px" />
          <h2>{title}</h2>
          <p className="subtitle">{subtitle}</p>
        </S.Left>
        <S.Form>
          <h3>
            {formTitle}
            <span>.</span>
          </h3>
          <FormActiveCampaign />
        </S.Form>
      </S.Container>
    </S.Contact>
  );
};

import ReactMarkdown from 'react-markdown';

import { ArrowRightIcon } from 'modules/icons/ArrowRight';
import { Circle2Icon } from 'modules/icons/Circle';

import * as S from './Hero.style';
import * as T from './Hero.types';

export const Hero: React.FC<T.HeroCollection> = ({ image, subtitle, title }) => {
  return (
    <S.Hero>
      <S.Container>
        <S.Left>
          <h1>
            <ReactMarkdown>{title}</ReactMarkdown>
            <span>.</span>
          </h1>
          <h6>
            <ReactMarkdown>{subtitle}</ReactMarkdown>
          </h6>
          <S.Contact>
            <S.Link href="#contato">
              agendar consultoria
              <ArrowRightIcon />
            </S.Link>
          </S.Contact>
        </S.Left>
        <S.Right>
          <img src={image.url} alt="Hero" height="1085px" width="1080px" />
          <Circle2Icon />
        </S.Right>
      </S.Container>
    </S.Hero>
  );
};
